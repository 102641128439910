import React, { useEffect, useRef, useState } from 'react';
import { ApiPath, ApiSecondaryPath, CryptoApiPath } from '../assets/common/base-url';
import { getDayOfWeek, getTodaysDate } from '../assets/common/operations';

export const DoctorsDialog = props => {

    const today = getTodaysDate();
    const [cbxSpeciality, setCbxSpeciality] = useState('Allergist')
    const [doctorsData, setDoctorsData] = useState('')
    const [selectedDoctor, setSelectedDoctor] = useState('')
    const [txtDate, setTxtDate] = useState(today);
    const [spanValue, setSpanValue] = useState('');
    const [timeOfCommencement, setTimeOfCommencement] = useState('');
    const [duration, setDuration] = useState(0);

    //The componentDidMount equivalent...
    //Fetch the doctors list...
    const params = {
        'headers': {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        'method': 'GET'
    }

    useEffect(() => {
        //Set the actual date and time of the selected slot...
        if (spanValue !== "") {
            const splitSpan = spanValue.split("-");
            const startTime = splitSpan[0].trim();
            const endTime = splitSpan[1].trim();

            setTimeOfCommencement(new Date(`${txtDate} ${startTime}:00`));
            setDuration(new Date(`${txtDate} ${endTime}:00`).getTime() - new Date(`${txtDate} ${startTime}:00`).getTime());

        }

    }, [spanValue])

    const fetchDoctors = () => {
        props.setIsLoaderVisible(true);

        fetch(ApiPath + "query/Doctor", params)
            ?.then(response => (response.json()))
            .then(res => {
                props.setIsLoaderVisible(false)

                if (res.statusCode === 200) {
                    const filteredData = res.data.filter(item => item.specialization?.type === cbxSpeciality)
                    setDoctorsData(filteredData);

                } else {
                    props.showToast(res.message, 'exclamation');
                }

            })
            .catch(err => {
                props.setIsLoaderVisible(false);
                props.showToast(err.message, 'exclamation');
            })

    }

    useEffect(() => {
        //Always refetch the doctors each time the speciality changes
        fetchDoctors();

    }, [cbxSpeciality])

    const selectDoctor = () => {
        //Call api to schedule appointment...
        //Get the details of the doctor in question...
        if (cbxSpeciality === "" || txtDate === "") {
            props.showToast("You must select both doctor's speciality and date to proceed.", "exclamation");
            return;
        }

        if (selectedDoctor === "") {
            props.showToast("You must select a doctor to proceed", "exclamation");
            return;
        }

        const thisDoctor = doctorsData.find(item => item.uidNo === selectedDoctor)
        props.setIsLoaderVisible(true);

        const input1 = `doctor_id=${thisDoctor.uidNo}&doctor_name=${thisDoctor.name}&doctor_email_id=${thisDoctor.emailId || 'somedoctor@mailer.com'}&`;
        const input2 = `patient_id=${props.data.uidNo}&patient_name=${props.data.name}&patient_email_id=${props.data.emailId || 'somepatient@gmail.com'}&`;
        const input3 = `starttime=${timeOfCommencement.getTime()}&duration=${duration}`;

        const options = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            },
            'method': 'POST',
        };

        props.setIsLoaderVisible(true);

        fetch(`${CryptoApiPath}scheduleAppointment?${input1}${input2}${input3}`, options)
            .then(response => (response.json()))
            .then(response => {
                if (response.status === 200) {
                    //Did a proper scheduling...
                    //Get the parameters...
                    props.setIsLoaderVisible(false);

                    props.setSpecialObject({
                        speciality: cbxSpeciality,
                        startTime: timeOfCommencement.getTime(),
                        duration: duration,
                        meetingID: response.result?.meeting_id,
                        doctor_id: thisDoctor.uidNo,
                        doctor_name: thisDoctor.name
                    })

                    //Set other parameters...
                    props.setDoctorSelected(selectedDoctor)
                    props.hideDoctorsDialog();
                }
            })
            .catch(err => {
                props.setIsLoaderVisible(false);
                props.showToast(err, "exclamation");
            })

    }

    //once the selected Doctor changes, use the useEffect hook to change the colors...

    const doctorsArray = doctorsData && doctorsData?.map((item, index) => {
        return (
            <DoctorsListItem
                key={index}
                selectedDoctor={selectedDoctor}
                setSelectedDoctor={setSelectedDoctor}
                id={item.uidNo}
                src={item.photo}
                name={item.name}
                specialization={item.specialization?.type}
                status={item.verification?.status}
                rating={4.3}
                spanValue={spanValue}
                setSpanValue={setSpanValue}
                active={selectedDoctor === item.uidNo ? true : false}
                selectedDay={getDayOfWeek(new Date(txtDate).getDay())}

            />
        )
    })

    return (
        <div className="modal fade show">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="nully">
                            <i className='icofont-doctor-alt'></i> Select Doctor
                        </h2>
                    </div>
                    <div className="modal-body mb-2">
                        <div className='form-row-3'>
                            <div className='input-group'>
                                <label>Specialization</label>
                                <select
                                    className="form-control"
                                    id="cbxSpeciality"
                                    value={cbxSpeciality}
                                    onChange={e => setCbxSpeciality(e.target.value)}
                                >
                                    <option value="">Select</option>
                                    <option value="Allergist">Allergist</option>
                                    <option value="Cardiologist">Cardiologist</option>
                                    <option value="Dermatologist">Dermatologist</option>
                                    <option value="Endocrinologist">Endocrinologist</option>
                                    <option value="Gastroenterologist">Gastroenterologist</option>
                                    <option value="Geriatrician">Geriatrician</option>
                                    <option value="Nephrologist">Nephrologist</option>
                                    <option value="Neurologist">Neurologist</option>
                                    <option value="OB/GYN">OB/GYN</option>
                                    <option value="Ophthalmologist">Ophthalmologist</option>
                                    <option value="Pediatrician">Pediatrician</option>
                                    <option value="Psychiatrist">Psychiatrist</option>
                                    <option value="Urologist">Urologist</option>
                                </select>
                            </div>
                            <div className='input-group'>
                                <label>Choose Date</label>
                                <input
                                    className='form-control'
                                    type='date'
                                    id='txtDate'
                                    value={txtDate}
                                    onChange={e => setTxtDate(e.target.value)}
                                />
                            </div>
                            <div className='wallet-balance' title='Wallet balance'>
                                <i className="bx bx-wallet-alt" />
                                <span>123 BTC</span>
                            </div>
                        </div>
                        <div className='form-row'>

                        </div>
                        <div className='form-row'>
                            <div className='doctors-list-container'>
                                {doctorsArray}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            id="btnUpdate"
                            onClick={selectDoctor}
                            className="btn-main mr-1"
                        ><i className="icofont-doctor"></i> Choose Doctor
                        </button>
                        <button
                            className="btn-main-outline"
                            onClick={() => props.hideDoctorsDialog()}
                        >Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const DoctorsListItem = props => {

    const [spanValue, setSpanValue] = useState('');
    const randomValue = useRef(Math.floor(Math.random() * 9));
    const [slotsArray, setSlotsArray] = useState([]);
    const [doctorVisible, setDoctorVisible] = useState('');

    const matchData = inputArray => {
        if (props.selectedDay === "Monday") {
            return inputArray.monday;
        } else if (props.selectedDay === "Tuesday") {
            return inputArray.tuesday;
        } else if (props.selectedDay === "Wednesday") {
            return inputArray.wednesday;
        } else if (props.selectedDay === "Thursday") {
            return inputArray.thursday;
        } else if (props.selectedDay === "Friday") {
            return inputArray.friday;
        } else if (props.selectedDay === "Saturday") {
            return inputArray.saturday;
        } else if (props.selectedDay === "Sunday") {
            return inputArray.sunday;
        }
    }

    //Here, we need to get slots for each doctor...
    useEffect(() => {
        //fetch...
        fetch(ApiSecondaryPath + "getSlots/" + props.id)
            .then(response => (response.json()))
            .then(response => {
                if (response && response.status === 'success' && response.message !== "No records available") {
                    //Display the expected output...
                    //Get the slots for the particular day...
                    const data = response.data[0].slots;

                    console.log(matchData(data));

                    //This will return an array of inputs (available_slot_details)
                    setSlotsArray(matchData(data)?.available_slot_details);

                    //Set the slot value to the very first item, if it exists..
                    if (matchData(data)?.available_slot_details?.length > 0) {
                        setDoctorVisible('');
                        setSpanValue(matchData(data)?.available_slot_details[0]);
                    } else {
                        //Hide the doctors with no slots on such days...
                        setDoctorVisible('d-none');
                    }

                } else {
                    //Hide the doctors with no slots on such days...
                    setDoctorVisible('d-none');
                }
            })

        //Always clear the selected doctor...
        props.setSelectedDoctor('');

    }, [props.selectedDay])

    const slotsArrayObject = slotsArray?.map((element, index) => {
        return (
            <span
                key={index}
                className={spanValue === element ? 'blue' : ''}
                onClick={() => setSpanValue(element)}>
                {element}
            </span>
        )
    })

    return (
        <div className={props.active ? 'doctor-area selected ' + doctorVisible : 'doctor-area ' + doctorVisible}>
            <div className="chosen-doctor">
                <div className='image-cont'>
                    <img src={props.src || "../portfolio/avatar.png"} alt="" />
                </div>

                {/* <p><span>♥</span>{props.rating}</p> */}
                <div className="details">
                    <h3>{props.name}</h3>
                    <p>{props.specialization}</p>
                    <div>
                        {slotsArrayObject}
                    </div>
                    <div className="schedule">
                        <p><i className='icofont-clock-time'></i>{' Choose Schedule (UTC)'}</p>
                        <button
                            style={{ float: 'right', marginTop: '10px' }}
                            className="btn-main"
                            onClick={() => {
                                props.setSelectedDoctor(props.id)
                                props.setSpanValue(spanValue)
                            }}
                        ><i className='icofont-check-circled' /> Select Doctor
                        </button>
                        <i
                            className={props.active ? "icofont-checked" : ''}
                            style={{ fontSize: '18px', color: 'var(--main-green)' }}
                        />
                    </div>
                    <div className='costing'>
                        <p><i className='icofont-bitcoin'></i> 1.{randomValue.current} BTC</p>
                    </div>

                </div>
            </div>

        </div>
    )
}

